import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import MauticForm from '../../components/ui/Forms/MauticForm/MauticForm';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './gansebook.scss';

interface GCVEProps {
    data: {
        file: FluidImage;
    };
}

const GCVE: React.FC<GCVEProps> = (props: GCVEProps) => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="GANs in Computer vision free course"
                url="gans-computer-vision-ebook/"
                description="Free ebook focused on GANs in Computer vision"
                image={props.data.file.childImageSharp.fluid.src}
            />
            <PageSection className="gansebook">
                <Container>
                    <Group size={2}>
                        <Block direction={Direction.vertical}>
                            <h1>GANs in Computer Vision free ebook</h1>
                            <h2>
                                This ebook follows the chronological order of 20
                                peer-reviewed highly-cited papers as they
                                presented in a series of 6 articles.
                            </h2>
                            <MauticForm
                                formId="2"
                                formName="gansebook"
                                isDark={false}
                            />
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="gansebook__image"
                        >
                            <Img
                                fluid={props.data.file.childImageSharp.fluid}
                                alt="GANs in Computer Vision free ebook"
                            ></Img>
                        </Block>
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const gansEbookQuery = graphql`
    {
        file(relativePath: { eq: "gans-ebook.png" }) {
            childImageSharp {
                fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }
    }
`;

export default GCVE;
